/*
Create a sortable fields table
@Get: data = array({ id [must], field_name...}), config = array(label, render, sortValue [optional])
@Return: a sortable table
 */
import React from 'react';
import { GoChevronUp, GoChevronDown } from "react-icons/go";
import Table from './Table';
import useSort from '../../hooks/use-sort';

function SortableTable(props) {
  const { config, data } = props;
  const { sortOrder, sortBy, sortedData, setSortColumn} = useSort(data, config);

  const updatedConfig = config.map((column) => {
    if (!column.sortValue) {
      return column;
    }

    return {
      ...column,
      header: () => <th onClick={() => setSortColumn(column.label)} className="cursor-pointer hover:bg-gray-100 hover:text-black">
        <div className="flex items-center">
          {getIcons(column.label, sortBy, sortOrder)}
          {column.label}
        </div>
      </th>
    };
  });

  return (
    <Table {...props} data={sortedData} config={updatedConfig} />
  );
}

function getIcons(label, sortBy, sortOrder) {
  if (label!==sortBy) {
    return <div>
      <GoChevronUp />
      <GoChevronDown />
    </div>
  }

  if (sortOrder===null) {
    return <div>
      <GoChevronUp />
      <GoChevronDown />
    </div>
  }
  else if (sortOrder==='asc') {
    return <div>
      <GoChevronUp />
    </div>
  }
  else if (sortOrder==='desc') {
    return <div>
      <GoChevronDown />
    </div>
  }
}

export default SortableTable;