import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { UserContext } from '../utils/UserContext';
import { Button } from "react-bootstrap";
import CardElement from "../parts/CardElement";
import API from "../api";
import Loader from "../Loader";

const Recipes = () => {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const [receiptsList, setReceiptsList] = useState([]);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [ formErr, setFormErr ] = useState('');

  useEffect(() => {
    setLoader(true);

    if (token!=="") {
      void API.get(`/recipes`, {
        headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
      })
        .then(({ data }) => {
          setReceiptsList(data);
          setLoader(false);
        }).catch(e => {
          console.log("error while getting recipes", e);
          setFormErr("Error while getting Recipes");
          setLoader(false);
        })
    }
  }, [token]);

  const handleCreateNew = () => {
    navigate("/Recipes/create");
  }

  return (
    <div className="mainContainer">

      { loader && <Loader /> }

      {
        formErr && <div className="alert alert-danger" role="alert">
          { formErr }
        </div>
      }

      <div className={"titleContainer"}>
        <h1>מתכונים</h1>
      </div>

      <div className="contentBlock">
        <Button variant="primary" onClick={handleCreateNew}>צור חדש</Button>
      </div>

      <div className="container text-center">
        <div className="row">

          {receiptsList.map((item) => (
            <CardElement key={item._id} item={item} role={userCredentials.role} />
          ))
          }

        </div>
      </div>
    </div>
  );
}

export default Recipes;