import axios from 'axios';

// const ENV = process.env.REACT_APP_ENV;
// let baseURL="";
// if (ENV==="production") {
//   baseURL = process.env.REACT_APP_PROD_DOMAIN;
// }
// else if (ENV==="stage") {
//   baseURL = process.env.REACT_APP_STAGE_DOMAIN;
// }
// else if (ENV==="local") {
//   baseURL = process.env.REACT_APP_LOCAL_DOMAIN;
// }

// local
// const baseURL = '//localhost:3011';

// // dev/prod
const baseURL = 'https://kerenfamilynetapi-f93eeb024d98.herokuapp.com';

// export default axios.create({
//   withCredentials: true,
//   baseURL
// });
export default axios.create({
  baseURL
});
