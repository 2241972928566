import React, { useState, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import SideBar from "./parts/SideBar/SideBar";
import Recipes from "./pages/Recipes";
import 'bootstrap/dist/css/bootstrap.min.css';
import classes from './App.module.css';
import Recipe from "./pages/Recipe";
import UserCreate from "./pages/UserCreate";
import Users from "./pages/Users";
import Logout from "./Logout";
import UserEdit from "./pages/UserEdit";
import RecipeCreate from "./pages/RecipeCreate";
import RecipeEdit from "./pages/RecipeEdit";

function App() {
  const auth = window.localStorage.getItem("user") !== null;
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")

  if (!auth) {
    return (
      <Routes>
        <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />

        <Route path='*' exact={true} element={<Login />} />
      </Routes>
    );
  }

  return (
    <Suspense fallback={<div>Loading...</div>} >
      <div className={classes.main} dir="rtl" >

        <div className="sideBar">
          <SideBar />
        </div>

        <div className="m-3">
          <Routes>
            <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />

            <Route path="/users" element={<Users />} />
            <Route path="/user/create" element={<UserCreate />} />
            <Route path="/user/edit/:id" element={<UserEdit />} />
            <Route path="/logout"  element={<Logout />} />

            <Route path="/recipes" element={<Recipes email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
            <Route path="/recipes/create" element={<RecipeCreate />} />
            <Route path="/recipes/:id/view" element={<Recipe />} />
            <Route path="/recipes/:id/edit" element={<RecipeEdit />} />

            <Route path='*' exact={true} element={<Home />} />
          </Routes>
        </div>
      </div>
    </Suspense>
  );
}

export default App;