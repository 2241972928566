import React, { useContext } from "react";
import { UserContext } from '../utils/UserContext';

const Home = () => {
    const { userCredentials } = useContext(UserContext);
    const { name, email } = userCredentials;

    return (
      <div className="mainContainer">
        <div className={"titleContainer"}>
            <div>Welcome, {name} ({email})!</div>
        </div>
        <div>
            דף הבית של משפחת קרן
        </div>
    </div>
    );
}

export default Home