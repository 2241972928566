/*
Create a none sortable fields table
@Get: data = array({ id [must], field_name...}), config = array(label, render)
@Return: a non sortable table
 */
import React from 'react';
import { Fragment } from 'react';

function Table({ data=[], config }) {
  const renderHeaders = config.map((column) => {
    if (column.header) {
      return <Fragment key={column.label}>{column.header()}</Fragment>;
    }

    return <th key={column.label}>{column.label}</th>
  });

  const renderedRows = data.map((rowData) => {
    const renderedCells = config.map((column) => {
      return <td className="p-2" key={column.label}>{column.render(rowData)}</td>
    });

    return (
      <tr className="border-b" key={rowData._id}>
        {renderedCells}
      </tr>
    )
  })

  return (
    <table className="table-auto border-spacing-2 w-full">
      <thead>
        <tr className="border-b-2">
          {renderHeaders}
        </tr>
      </thead>
      <tbody>
        {renderedRows}
      </tbody>
    </table>
  );
}

export default Table;