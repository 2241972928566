import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import classes from './CardElement.module.css';
import API from "../api";
import {UserContext} from "../utils/UserContext";
import Avatar from '@mui/material/Avatar';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import NightlightRoundOutlinedIcon from '@mui/icons-material/NightlightRoundOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const CardElement = ({item, role}) => {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const navigate = useNavigate();
  const previewImage = item.previewImage!==undefined ? btoa(String.fromCharCode(...new Uint8Array(item.previewImage.data.data))) : null;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = (recipeID) => {
    setOpen(false);
    handleDelete(recipeID);
  };

  const handleDelete = (recipeID) => {
    void API.delete(`/recipes/${recipeID}/delete`, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(() => {
        window.location.reload(false);
      }).catch(e => {
        console.log("error getting recipe data", e);
      })
  }

  return (
    <Card style={{ width: '18rem' }} className="m-2 p-1" key={item._id}>
      {
        previewImage!==null && <Card.Img variant="top" src={`data:image/png;base64,${previewImage}`} />
      }

      <Card.Body>
        {
          (item.morning===true || item.lunch===true || item.evening===true || item.desserts===true) &&
          <div className={classes.titleIcon}>
            <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
              {
                item.morning===true && <LightModeOutlinedIcon />
              }
              {
                item.lunch===true &&  <RestaurantOutlinedIcon />
              }
              {
                item.evening===true && <NightlightRoundOutlinedIcon />
              }
              {
                item.desserts===true && <CakeOutlinedIcon />
              }
            </Avatar>

            <Card.Title>{item.title}</Card.Title>
          </div>
        }
        {
          item.other===true && <Card.Title>{item.title}</Card.Title>
        }



        <div className={classes.actionBtns}>
          <Button variant="primary" onClick={() => {
            // console.log("redirect to /Recipes/", item._id);
            navigate(`/recipes/${item._id}/view`);
          }}>פרטים</Button>
          <Button variant="secondary" onClick={() => {
            // console.log("redirect to /Recipes/", item._id);
            navigate(`/recipes/${item._id}/edit`);
          }}>עריכה</Button>
          {
            role==='admin' &&
            <>
              <Button variant="danger" onClick={() => {
                handleClickOpen()
              }}>מחיקה</Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"אישור לפני מחיקה"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose} variant="secondary">סרב</Button>
                  <Button onClick={() => {
                    handleDeleteClose(item._id);
                  }} autoFocus variant="danger">
                    אשר
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          }
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardElement;