import React, { useContext } from 'react';
import Container from 'react-bootstrap/Container';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {UserContext} from '../../utils/UserContext';
import classes from './SideBar.module.css';
import Footer from "../footer/Footer";

const Sidebar = () => {
  const currentURL = window.location.pathname;
  const {userCredentials} = useContext(UserContext);

  return (
    <Navbar expand="lg" className={`navbar navbar-dark bg-dark ${classes.fullHeight}`} bg="dark" >
      <Container className={classes.columnItems} >
        <Navbar.Brand to="/" as={Link}>
          משפחת קרן
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={classes.columnItems}>
          <Nav className={`me-auto ${classes.columnItems}`} activeKey={currentURL} >
            <Nav.Link to="/recipes" as={Link} className={currentURL.includes("Tea-table")? classes.activeTag : ""} >
              מתכונים
            </Nav.Link>
            {
              userCredentials.role==='admin' &&
              <>
                <Nav.Link to="/users" as={Link} className={currentURL.includes("Wood-aging-part")? classes.activeTag : ""} >
                  משתמשים/ות
                </Nav.Link>
                <NavDropdown title="צור חדש" id="basic-nav-dropdown" bg="dark">
                  <NavDropdown.Item to="/recipes/create" as={Link}>מתכון</NavDropdown.Item>
                  <NavDropdown.Item to="/user/create" as={Link}>משתמש</NavDropdown.Item>
                </NavDropdown>
              </>
            }
            <Nav.Link to="/logout" as={Link} className={currentURL.includes("Small-cabinet")? classes.activeTag : ""} >
              התנתק/י
            </Nav.Link>
          </Nav>

          <Footer />
        </Navbar.Collapse>

      </Container>
    </Navbar>
  );
};

export default Sidebar;