import React, { useState, useContext } from 'react';
import {Form, Row, Button} from "react-bootstrap";
import API from '../api';
import {UserContext} from '../utils/UserContext';
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

function UserCreate() {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const navigate = useNavigate();
  const [ validated, setValidated ] = useState(false);
  const [ formErr, setFormErr ] = useState('');
  const [ formSuccess, setFormSuccess ] = useState('');
  const [ formValues, setFormValues ] = useState({
    name: '',
    email: '',
    password: '',
  });
  const [loader, setLoader] = useState(false);

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    else {
      setLoader(true);

      void API.post(`/users/create`, {
        name: formValues.name,
        email: formValues.email,
        password: formValues.password
      }, {
        headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
      })
        .then(({data}) => {
          // console.log("user created successfully", data);
          setTimeout(function() {
            navigate("/users");
          }, 2000);

          setFormSuccess('נשמר בהצלחה.');
        }).catch(e => {
          setLoader(false);
          setFormErr('משהו נכשל בשמירה, נסה שוב מאוחר יותר.');
        })
    }
  }

  return (
    // <form className="container mt-3 mb-3" noValidate validated={validated} onSubmit={handleSubmit}>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>

      { loader && <Loader /> }

      <h1>הוספת משתמש/ת חדש/ה</h1>

      {
        formSuccess && <div className="alert alert-success" role="alert">
          { formSuccess }
        </div>
      }
      {
        formErr && <div className="alert alert-danger" role="alert">
          { formErr }
        </div>
      }

      <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
          <Form.Label>Name</Form.Label>
          <Form.Control type="name" name="name" required value={formValues.name} onChange={handleChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            Please enter your name.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
          <Form.Label>EMail</Form.Label>
          <Form.Control type="email" name="email" required value={formValues.email} onChange={handleChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            Please enter your valid email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
          <Form.Label>Password</Form.Label>
          <Form.Control type="text" name="password" required value={formValues.password} onChange={handleChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            Please choose a password. Must be at least 6 character with 1 capital letter.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
          {/*<button type="submit" className="me-4 btn btn-success btn-lg btn-block">Submit</button>*/}
          <Button type="submit">Submit</Button>
        </Form.Group>
      </Row>
    </Form>
  );
}

export default UserCreate;
