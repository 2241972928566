import React, {useState, useContext, useEffect} from 'react';
import {Form, Row, Button} from "react-bootstrap";
import API from '../api';
import {UserContext} from '../utils/UserContext';
import Loader from "../Loader";

function UserEdit() {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const [loader, setLoader] = useState(false);
  const [validated, setValidated] = useState(false);
  const [ formErr, setFormErr ] = useState('');
  const [ formSuccess, setFormSuccess ] = useState('');
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    setLoader(true);

    const id = window.location.pathname.replace('/user/edit/', '');
    void API.get(`/users/${id}`, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(({data}) => {
        setFormValues({
          name: data.name,
          email: data.email,
          password: ''
        })

        setLoader(false);
      }).catch(e => {
        console.log("error while creating new user", e);
      })
  }, [token])

  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const id = window.location.pathname.replace('/user/edit/', '');

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    setValidated(true);

    void API.patch(`/users/${id}`, {
      name: formValues.name,
      email: formValues.email,
      password: formValues.password
    }, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(({data}) => {
        setFormSuccess('עודכן בהצלחה.')
      }).catch(e => {
        setFormErr('משהו נכשל בשמירה, נסה שוב מאוחר יותר.');
      })
  }

  return (
    // <form className="container mt-3 mb-3" noValidate validated={validated} onSubmit={handleSubmit}>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>

      { loader && <Loader /> }

      {
        formSuccess && <div className="alert alert-success" role="alert">
          { formSuccess }
        </div>
      }
      {
        formErr && <div className="alert alert-danger" role="alert">
          { formErr }
        </div>
      }

      <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
          <Form.Label>שם פרטי</Form.Label>
          <Form.Control type="name" name="name" required value={formValues.name} onChange={handleChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            Please enter your name.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
          <Form.Label>אימייל</Form.Label>
          <Form.Control type="email" name="email" required value={formValues.email} onChange={handleChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            Please enter your valid email.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formBasicEmail" className="col col-sm-6">
          <Form.Label>סיסמא</Form.Label>
          <Form.Control type="text" name="password" required value={formValues.password} onChange={handleChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            Please choose a password. Must be at least 6 character with 1 capital letter.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
          {/*<button type="submit" className="me-4 btn btn-success btn-lg btn-block">Submit</button>*/}
          <Button type="submit">Submit</Button>
        </Form.Group>
      </Row>
    </Form>
  );
}

export default UserEdit;
