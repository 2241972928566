import Spinner from 'react-bootstrap/Spinner';
import classes from './Loader.module.css';

const Loader = () => {
  return (
    <div className={classes.overlay}>
      <Spinner animation="border" role="status" variant="secondary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
}

export default Loader;