import React, {useEffect, useContext, useState} from 'react';
import {Container, Button} from "react-bootstrap";
import SortableTable from "../../components/SortableTable/SortableTable";
import {Link} from "react-router-dom";
import API from "../api";
import {UserContext} from "../utils/UserContext";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";

function Users() {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const navigate = useNavigate();
  const [ usersList, setUsersList ] = useState([ ]);
  const [loader, setLoader] = useState(false);

  const editBtnClasses = "text-red-500 rounded-4 border border-gray-400 text-center hover:bg-gray-300 hover:cursor-pointer";

  useEffect(() => {
    if (token!=="") {
      setLoader(true);

      void API.get(`/users`, {
        headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
      })
        .then(({ data }) => {
          setUsersList(data);
          setLoader(false);
        }).catch(e => {
          console.log("error while getting users", e);
        })
    }
  }, [token])


  const config = [
    { label: 'שם', render: (item) => item.name, sortValue: (item) => item.name },
    { label: 'מייל', render: (item) => item.email },
    // { label: '_id', render: (item) => item._id },
    // {
    //   label: 'Status',
    //   render: (item) =>
    //     <div style={{color:item.status==="active" ? 'green' :
    //         item.status==="not active" ? 'red' : 'yellow'}}>{item.status}</div>,
    //   sortValue: (item) => item.status
    // },
    {
      label: 'פעילות',
      render: (item) =>
        <div className="grid grid-cols-2 gap-2">
          <Link to={`/user/edit/${item._id}`} className={editBtnClasses}>עריכה</Link>
        </div>
    }
  ];

  const handleCreateNew = () => {
    navigate("/user/create");
  }

  return (
    <Container>

      <div className="contentBlock">
        <Button variant="primary" onClick={handleCreateNew}>צור חדש</Button>
      </div>

      { loader && <Loader /> }

      <SortableTable data={usersList} config={config} />
    </Container>
  );
}

export default Users;
