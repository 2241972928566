import React, {createContext, useEffect, useState} from 'react';

// Set initial values for user credentials
const initialUserCredentials = {
  // password: '',
  // username: '',
  // csrf_token: '',
  // logout_token: '',
  // user: ''
  token: '',
  name: '',
  email: '',
  role: ''
};

// Create the UserContext
export const UserContext = createContext(initialUserCredentials);

export const UserProvider = ({ children }) => {
  const [userCredentials, setUserCredentials] = useState(initialUserCredentials);

  const updateUserCredentials = (newCredentials) => {
    setUserCredentials((prevCredentials) => ({
      ...prevCredentials,
      ...newCredentials,
    }));
  }

  useEffect(() => {
    if (window.localStorage.getItem("user")!==null) {
      const {token, name, email, role} = JSON.parse(window.localStorage.getItem("user"));
      const newCredentials = {
        token,
        name,
        email,
        role
      };

      updateUserCredentials(newCredentials);
    }
  }, []);

  return (
    <UserContext.Provider value={{ userCredentials, updateUserCredentials }}>
      {children}
    </UserContext.Provider>
  );
};