import React, {useContext, useEffect, useState} from 'react';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../utils/UserContext";
import API from "../api";
import Loader from "../Loader";
import Button from 'react-bootstrap/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import classes from './Recipe.module.css';

const Recipe = () => {
  const navigate = useNavigate();
  const baseURL = 'https://kerenfamilynetapi-f93eeb024d98.herokuapp.com';
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const [recipeData, setRecipeData] = useState({
    title: '',
    source: '',
    morning: false,
    lunch: false,
    evening: false,
    images: null
  });
  const [previewImage, setPreviewImage] = useState(null);
  const [image, setImage] = useState(null);
  const [pdfFile, setPdfFile] = useState(null);
  const [side2, setSide2] = useState(null);
  const [loader, setLoader] = useState(false);
  let urLString = window.location.pathname.replace('/recipes/', '');
  const [recipeID] = useState(urLString.replace('/view', ''));
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setLoader(true);

    void API.get(`/recipes/${recipeID}`, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(({data}) => {
        setRecipeData({...data});
        if (data.previewImage!==undefined) {
          setPreviewImage(btoa(String.fromCharCode(...new Uint8Array(data.previewImage.data.data))));
        }

        if (data.side1.type==="image/jpg" || data.side1.type==="image/jpeg") {
          setImage(btoa(String.fromCharCode(...new Uint8Array(data.side1.data.data))));
        }
        else if (data.side1.type==="application/pdf") {
          // // setPdfFile(btoa(String.fromCharCode(...new Uint8Array(data.images[0].data.data))));
          // setPdfFile(data.images[0].data.data);
          void API.get(`/recipes/${recipeID}/image`, {
            headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
          })
            .then(({data}) => {
              setPdfFile(data);
            }).catch(e => {
              console.log("error getting recipe data", e);
            })
        }

        if (data.side2!==undefined) {
          if (data.side2.type==="image/jpg" || data.side2.type==="image/jpeg") {
            setSide2(btoa(String.fromCharCode(...new Uint8Array(data.side2.data.data))));
          }
          else { //if (data.side2[0].type==="application/pdf") {
            // void API.get(`/recipes/${recipeID}/image`, {
            //   headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
            // })
            //   .then(({data}) => {
            //     setPdfFile(data);
            //   }).catch(e => {
            //     console.log("error getting recipe data", e);
            //   })
            console.log("not support side2 pdf file type.");
          }
        }

        setLoader(false);
      }).catch(e => {
        console.log("error getting recipe data", e);
      })
  }, [token, recipeID])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = () => {
    setOpen(false);
    handleDelete();
  };

  const handleDelete = () => {
    void API.delete(`/recipes/${recipeID}/delete`, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(({data}) => {
        navigate(`/recipes`);
      }).catch(e => {
        console.log("error getting recipe data", e);
      })
  }

  return (
    <div className="mainContainer">

      { loader && <Loader /> }

      <h2>{recipeData.title}</h2>

      {
        previewImage && <img src={`data:image/png;base64,${previewImage}`} alt={recipeData.title} width={'25%'}/>
      }

      <p>
        { recipeData.morning===true ? 'בוקר ' : null }
        { recipeData.lunch===true ? 'צהריים ' : null }
        { recipeData.evening===true ? 'ערב' : null }
        { recipeData.desserts===true ? 'קינוחים' : null }
        { recipeData.other===true ? 'אחר' : null }
      </p>

      <div>
        side 1:
        {
          image!==null && <img src={`data:image/png;base64,${image}`}  alt={recipeData.title}/>
        }
        {
          pdfFile!==null &&
          <iframe src={`${baseURL}/recipes/${recipeID}/image`} title="title" style={{width:'100%', height:'115vh'}}>
          </iframe>
        }
      </div>

      <div>
      side 2:
      {
        side2!==null && <img src={`data:image/png;base64,${side2}`}  alt={recipeData.title}/>
      }
      </div>

      <div className={classes.actionBtn}>
        {/*<Button variant="danger" onClick={handleDelete}>מחיקה</Button>*/}
        <Button variant="danger" onClick={() => {
          handleClickOpen()
        }}>מחיקה</Button>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"אישור לפני מחיקה"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} variant="secondary">סרב</Button>
          <Button onClick={() => {
            handleDeleteClose();
          }} autoFocus variant="danger">
            אשר
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Recipe;